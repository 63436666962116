// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'YOUR_API_KEY',
  authDomain: 'tutionpointweb.firebaseapp.com',
  databaseURL: "https://tutionpointweb-default-rtdb.asia-southeast1.firebasedatabase.app" ,
  projectId: 'tutionpointweb',
  storageBucket: 'tutionpointweb.appspot.com',
  messagingSenderId: '620838286970',
  appId: '1:620838286970:web:686de7f3991803da196dc3',
  measurementId: 'G-M9Y1G3RT1X',
   // Correct region URL
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const analytics = getAnalytics(app);

export { database, analytics };
