import React, { useState } from 'react';
import './ContactUsPage.css';
import { database } from './firebaseConfig';
import { ref, push } from 'firebase/database';
import Footer from './Footer';

const ContactUsPage = () => {

  const handleCall = () => {
    window.open('tel:9354955543');
  };
  // Student Form State
  const [studentData, setStudentData] = useState({
    name: '',
    class: '',
    subjects: '',
    school: '',
    location: '',
    pincode: '',
    mobileNumber: '',
    query: '',
  });

  // Tutor Form State
  const [tutorData, setTutorData] = useState({
    name: '',
    qualification: '',
    classes: '',
    subjects: '',
    mobileNumber: '',
    email: '',
    address: '',
    pincode: '',
  });

  // Handling input changes for Student Form
  const handleStudentChange = (e) => {
    setStudentData({ ...studentData, [e.target.name]: e.target.value });
  };

  // Handling input changes for Tutor Form
  const handleTutorChange = (e) => {
    setTutorData({ ...tutorData, [e.target.name]: e.target.value });
  };

  // Submitting Student Form
  const handleStudentSubmit = (e) => {
    e.preventDefault();
    push(ref(database, 'student_query'), studentData)
      .then(() => {
        alert('Student query submitted successfully');
        setStudentData({
          name: '',
          class: '',
          subjects: '',
          school: '',
          location: '',
          pincode: '',
          mobileNumber: '',
          query: '',
        });
      })
      .catch((error) => {
        console.error('Error submitting student query:', error);
      });
  };

  const handleTutorSubmit = (e) => {
    e.preventDefault();
    push(ref(database, 'teacher_query'), tutorData)
      .then(() => {
        alert('Tutor query submitted successfully');
        setTutorData({
          name: '',
          qualification: '',
          classes: '',
          subjects: '',
          mobileNumber: '',
          email: '',
          address: '',
          pincode: '',
        });
      })
      .catch((error) => {
        console.error('Error submitting tutor query:', error);
      });
  };

  return (
    <div>
    <div className="contact-us-page">
      <div className="contact-us-text">
      <h1>Contact Us</h1>
      </div>
      <div className="forms-container">
        {/* Student Form */}
        <form className="form student-form" onSubmit={handleStudentSubmit}>
          <h2>Parents/Student Form</h2>
         
          <div className="input-container">
            <input
              type="text"
              name="name"
              value={studentData.name}
              onChange={handleStudentChange}
              required
              maxLength="50"
            />
            <label>Name</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="class"
              value={studentData.class}
              onChange={handleStudentChange}
              required
            />
            <label>Class</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="subjects"
              value={studentData.subjects}
              onChange={handleStudentChange}
              required
              maxLength="100"
            />
            <label>Subjects</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="school"
              value={studentData.school}
              onChange={handleStudentChange}
              required
            />
            <label>School</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="location"
              value={studentData.location}
              onChange={handleStudentChange}
              required
            />
            <label>Location (Area, Colony, Street)</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="pincode"
              value={studentData.pincode}
              onChange={handleStudentChange}
              required
              pattern="[0-9]{6}"
              title="Please enter a valid 6-digit pincode"
            />
            <label>Pincode</label>
          </div>
          <div className="input-container">
            <input
              type="tel"
              name="mobileNumber"
              value={studentData.mobileNumber}
              onChange={handleStudentChange}
              required
              pattern="[0-9]{10}"
              title="Please enter a valid 10-digit mobile number"
            />
            <label>Mobile Number</label>
          </div>
          <div className="input-container">
            <textarea
              name="query"
              value={studentData.query}
              onChange={handleStudentChange}
              maxLength="500"
            />
            <label>Query (Optional)</label>
          </div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>

        {/* Tutor Form */}
        <form className="form tutor-form" onSubmit={handleTutorSubmit}>
          <h2>Tutors Form</h2>
          <div className="input-container">
            <input
              type="text"
              name="name"
              value={tutorData.name}
              onChange={handleTutorChange}
              required
              maxLength="50"
            />
            <label>Name</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="qualification"
              value={tutorData.qualification}
              onChange={handleTutorChange}
              required
              maxLength="100"
            />
            <label>Qualification</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="classes"
              value={tutorData.classes}
              onChange={handleTutorChange}
              required
            />
            <label>Classes (From-To)</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="subjects"
              value={tutorData.subjects}
              onChange={handleTutorChange}
              required
              maxLength="100"
            />
            <label>Subjects</label>
          </div>
          <div className="input-container">
            <input
              type="tel"
              name="mobileNumber"
              value={tutorData.mobileNumber}
              onChange={handleTutorChange}
              required
              pattern="[0-9]{10}"
              title="Please enter a valid 10-digit mobile number"
            />
            <label>Mobile Number</label>
          </div>
          <div className="input-container">
            <input
              type="email"
              name="email"
              value={tutorData.email}
              onChange={handleTutorChange}
              required
            />
            <label>Email Id</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="address"
              value={tutorData.address}
              onChange={handleTutorChange}
              required
            />
            <label>Address</label>
          </div>
          <div className="input-container">
            <input
              type="text"
              name="pincode"
              value={tutorData.pincode}
              onChange={handleTutorChange}
              required
              pattern="[0-9]{6}"
              title="Please enter a valid 6-digit pincode"
            />
            <label>Pincode</label>
          </div>
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
       
      </div>
      <button className="contact-button" onClick={handleCall}>
        Call Us Now
      </button>
      <div className="call-us-text">
      <h1>Call us now to discover the best home tutoring experience!!</h1>
      </div>
     
    </div>
    <Footer />
    </div>
  );
};

export default ContactUsPage;
