import React from 'react';
import './Navbar.css';

const Navbar = ({ onNavClick }) => (
  <nav className="navbar">
    <ul className="navbar-list">
      <li onClick={() => onNavClick('home')}>Home</li>
      <li onClick={() => onNavClick('services')}>Services</li>
      <li onClick={() => onNavClick('about')}>About Us</li>
      <li onClick={() => onNavClick('contact')}>Contact</li>
    </ul>
  </nav>
);

export default Navbar;
