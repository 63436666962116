import React from 'react';
import './ClientFeedback.css';

const feedbacks = [
  { name: 'Aarav Sharma', feedback: 'TuitionPoint has been amazing! My grades improved drastically thanks to the personalized attention from my tutor.', rating: 5 },
  { name: 'Riya Gupta', feedback: 'The tutors at TuitionPoint are very knowledgeable and patient. I was able to understand complex topics with ease.', rating: 4.8 },
  { name: 'Vihaan Patel', feedback: 'The tutor I was paired with was excellent. They understood my weaknesses and helped me improve them.', rating: 4.9 },
  { name: 'Ananya Deshmukh', feedback: 'I have never been more confident in my studies. TuitionPoint has truly made a difference in my learning.', rating: 5 },
  { name: 'Ishaan Verma', feedback: 'Great service and fantastic tutors. I could see a noticeable improvement in my academic performance.', rating: 4.7 },
  { name: 'Kavya Nair', feedback: 'I struggled with math for years, but TuitionPoint’s tutor made it easy and enjoyable. Highly recommended!', rating: 4.9 },
  { name: 'Arjun Mehta', feedback: 'TuitionPoint provided a tutor who was a perfect match for my learning style. My grades have improved a lot.', rating: 4.8 },
  { name: 'Meera Joshi', feedback: 'The tutor was very professional and helped me prepare for my exams efficiently. I scored really well!', rating: 5 },
  { name: 'Rahul Singh', feedback: 'The tutors are very supportive and they really helped me overcome my fear of exams.', rating: 4.7 },
  { name: 'Sanya Kapoor', feedback: 'Thanks to TuitionPoint, I am now excelling in subjects I once found difficult.', rating: 5 },
  { name: 'Devansh Kulkarni', feedback: 'TuitionPoint connected me with a tutor who really understood my learning needs. It was a great experience.', rating: 4.9 },
  { name: 'Tara Iyer', feedback: 'The tutors at TuitionPoint are very skilled. They helped me boost my confidence and improve my grades.', rating: 4.8 },
  { name: 'Aman Choudhary', feedback: 'TuitionPoint provided me with the guidance I needed to excel in my exams. I couldn’t be happier!', rating: 5 },
  { name: 'Pooja Rao', feedback: 'The personalized tutoring I received from TuitionPoint was top-notch. I am really grateful for their support.', rating: 4.9 },
  { name: 'Rohan Bhatt', feedback: 'I found the tutors at TuitionPoint to be very knowledgeable and helpful. My performance has improved significantly.', rating: 4.8 },
];

const duplicatedFeedbacks = feedbacks.concat(feedbacks); // Duplicate the feedbacks for seamless infinite scrolling

const ClientFeedback = () => (
  <div className="client-feedback-section">
    <h6>Client Feedback</h6>
    <div className="feedback-list-wrapper">
      <div className="feedback-list">
        {duplicatedFeedbacks.concat(duplicatedFeedbacks).map((feedback, i) => ( // Duplicating feedback for smooth scroll
          <div key={i} className="feedback-item">
            <h4>{feedback.name}</h4>
            <div className="rating">
              {'★'.repeat(Math.floor(feedback.rating))}
              {feedback.rating % 1 !== 0 && '☆'}
            </div>
            <p>"{feedback.feedback}"</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);


export default ClientFeedback;
