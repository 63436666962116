import React from 'react';
import './QuestionBanners.css';

const QuestionBanners = () => (
  <div className="questions">
    <div className="question-item">
      <img src="/images/image1.jpg" alt="Worried about performance" />
      <h4>Are you worried about your child's poor academic performance?</h4>
    </div>
    <div className="question-item">
      <img src="/images/image2.jpg" alt="Tired of searching" />
      <h4>Are you tired of searching for a reliable tutor?</h4>
    </div>
    <div className="question-item">
      <img src="/images/image3.jpg" alt="Tuition Fees" />
      <h4>Is the tuition fee too much?</h4>
    </div>
  </div>
);

export default QuestionBanners;
