import React from 'react';
import './ServicesSection.css';
import Footer from './Footer';

const ServicesSection = () => (
  <div>
  <div className="services-section">
    <div className="services-description">
      <h1>Our Services</h1>
      <p>We provide Home Tutors for one-on-one classes. We thoroughly vet our tutors and ensure they are verified. Both professional and part-time tutors are available with us.</p>

      
      <ul>
        <li>Classes I-X: All subjects</li>
        <li>Classes XI-XII: Physics, Chemistry, Mathematics, Biology, Accounts, Business Studies, Economics, History, Political Science, English, Hindi</li>
      </ul>
    </div>

    <div className="services-banners">
      <div className="service-banner">
        <h3>Dedicated Tutors</h3>
        <p>Our dedicated tutors are committed to enhancing students' learning experiences and achieving academic success.</p>
      </div>
      <div className="service-banner">
        <h3>Tailored Learning</h3>
        <p>We offer personalized tutoring solutions designed to meet each student's unique educational needs and goals.</p>
      </div>
      <div className="service-banner">
        <h3>Professional Guidance</h3>
        <p>Our agency connects you with experienced tutors who focus on individual learning styles and preferences.</p>
      </div>
    </div>
    
  </div>
  <Footer/>
  </div>
  
);

export default ServicesSection;
