import React from 'react';
import './VideoSection.css';
import Banners from './Banners';

const VideoSection = ({ handleCall }) => (
  <div className="video-section">
    <video className="video-bg" autoPlay loop muted>
      <source src="/videos/student_teacher.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div className="video-overlay">
      <h6 className="company-title">TuitionPoint</h6>
      <p className="slogan">Connect, Learn & Excel</p>
      <p className="subtitle">Learn from the Best Home Tutors</p>
      <p className="description">Providing qualified home tutors across Delhi NCR for over a decade.</p>
      <button className="contact-button" onClick={handleCall}>
        Contact Us
      </button>
      <div className="banners-container">
      <Banners/>
      </div>
    </div>
  </div>
);

export default VideoSection;
