import React from 'react'; 
import './CallToAction.css';
import whatsappIcon from '../assets/whatsapp.svg'; 

const CallToAction = ({ handleCall }) => {
  const handleChatWithUs = () => {
    // Opens WhatsApp chat (replace with your WhatsApp number)
    window.open('https://wa.me/9354955543', '_blank');
  };

  return (
    <div className="call-action">
      <h6>Worry Not! We are here to the rescue!</h6>
      <div className="button-container">
        <button onClick={handleCall}>Call Now</button>
        
        <button className="chatWithUs-button" onClick={handleChatWithUs}>
          <img src={whatsappIcon} alt="WhatsApp Icon" className="whatsapp-icon" />
          Chat Now
        </button>
      </div>
    </div>
  );
}
  

export default CallToAction;
