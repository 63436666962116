import React from 'react';
import './AboutUsSection.css';
import Footer from './Footer';

const AboutUsSection = () => {
  const handleCall = () => {
    window.open('tel:9354955543');
  };

  return (
    <>
      <div className="about-us-container">
        {/* About Us Section */}
        <div className="about-us-section">
          <h1>About Us</h1>
          <p className="intro-text">
            At <strong>TuitionPoint</strong>, we believe that personalized education is the key to unlocking a student’s full potential. We are a dedicated home tutor agency based in Delhi NCR, providing exceptional educational services for over a decade. Our mission is simple: to connect parents with qualified tutors at no cost to them.
          </p>
        </div>

        {/* What Makes Us Different Section */}
        <div className="differentiator-section">
          <h2>What Makes Us Different?</h2>
          <div className="differentiator-cards">
            <div className="differentiator-card">
              <h3>Individualized Attention</h3>
              <p>One-on-one tutoring tailored to each student’s unique learning needs.</p>
            </div>
            <div className="differentiator-card">
              <h3>Experienced & Qualified Tutors</h3>
              <p>Our 5,000+ tutors are experts in their subjects, ranging from professionals to highly skilled educators.</p>
            </div>
            <div className="differentiator-card">
              <h3>Comprehensive Subjects</h3>
              <p>From primary classes to advanced high school subjects including Math, Science, Commerce, Arts, and more.</p>
            </div>
            <div className="differentiator-card">
              <h3>Hassel Free Experience</h3>
              <p>Find best experienced and verified tutors from the comfort of your home.</p>
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="stats-section">
          <div className="stat-item">
            <h2>5,000+</h2>
            <p>Tutors available</p>
          </div>
          <div className="stat-item">
            <h2>9,000+</h2>
            <p>Students tutored</p>
          </div>
          <div className="stat-item">
            <h2>10+ Years</h2>
            <p>Of trusted educational service</p>
          </div>
        </div>

        {/* Testimonial Section */}
        <div className="testimonial-section">
          <h2>Why Parents Trust Us</h2>
          <p>"TuitionPoint helped us find the perfect tutor for our child. The improvement in their grades has been remarkable, and they’ve become more confident in their studies!" – <em>Anjali, Parent</em></p>
        </div>

        {/* Mission Section */}
        <div className="mission-section">
          <h2>Our Mission</h2>
          <p>
            Our mission is to bridge the gap between students and the finest tutors, ensuring quality education is accessible to all. With over 10 years of experience, we’ve built a reputation for trust, excellence, and delivering results.
          </p>
        </div>

        {/* Contact Section */}
        <div className="contact-section">
          <button className="contact-button" onClick={handleCall}>
            Contact Us Now
          </button>
          <div className="call-us-text">
      <h1>Call us now to discover the best home tutoring experience!!</h1>
      </div>
        </div>
        
      </div>

      <Footer />
    </>
  );
};

export default AboutUsSection;
