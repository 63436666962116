import React from 'react';
import './Banners.css';

const Banners = () => (
  <div className="banners">
    <div className="banner">
      <h3>Qualified Teachers</h3>
      <p>Connect with experienced tutors for personalized one-on-one learning.</p>
    </div>
    <div className="banner">
      <h3>Tailored Learning</h3>
      <p>Personalized learning experiences tailored to your child's unique needs</p>
    </div>
    <div className="banner">
      <h3>Student Success</h3>
      <p>Get the best home tutoring experience today.</p>
    </div>
  </div>
);

export default Banners;
