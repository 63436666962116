import React from 'react';
import './CombinedSection.css';

const CombinedSection = () => (
  <div className="combined-section">
    <div className="section-title">
      <h6>Our Solutions To All Your Problems</h6>
    </div>
    <div className="solutions-banners">
      <div className="solution-banner">
        <h3>Highly Qualified Tutors</h3>
        <p>Our tutors are highly qualified and experienced. They know how to make the sessions engaging so that your child loves learning.</p>
      </div>
      <div className="solution-banner">
        <h3>Reliable Tutors</h3>
        <p>We vet our tutors thoroughly before onboarding them. All tutors are verified by IDs and photos to ensure authenticity.</p>
      </div>
      <div className="solution-banner">
        <h3>Affordability</h3>
        <p>We have a range of tutors for every budget, so even low-income households can afford quality education.</p>
      </div>
    </div>

    <div className="section-title">
      <h6>How Does It Work?</h6>
    </div>
    <div className="steps">
      <div className="step">
        <h4>Submit Your Problems</h4>
        <p>Tell us about your requirements, such as what kind of tutor you need and for which grade.</p>
      </div>
      <div className="step">
        <h4>We Find a Tutor</h4>
        <p>We refer the perfect tutor based on your requirements from our extensive database.</p>
      </div>
      <div className="step">
        <h4>Book a Demo</h4>
        <p>Book a demo class, and if you're satisfied, start the sessions immediately.</p>
      </div>
    </div>
  </div>
);

export default CombinedSection;
