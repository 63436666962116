import React from 'react';

import VideoSection from './components/VideoSection';

import QuestionBanners from './components/QuestionBanners';
import CallToAction from './components/CallToAction';

import CombinedSection from './components/CombinedSection';
import ClientFeedback from './components/ClientFeedback';
import Footer from './components/Footer';


const HomePage = () => {
  const handleCall = () => {
    window.open('tel:9354955543');
  };

  return (
    <>
      
      <VideoSection handleCall={handleCall} />

      <QuestionBanners />
      <CallToAction handleCall={handleCall} />
      <CombinedSection/>
      <ClientFeedback />
      <Footer />
    </>
  );
};

export default HomePage;
