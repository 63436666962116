import React, { useState } from 'react';
import './App.css';
import HomePage from './HomePage';
import ServicesSection from './components/ServicesSection';
import AboutUsSection from './components/AboutUsSection';
import ContactUsPage from './components/ContactUsPage';
import Navbar from './components/Navbar';

function App() {
  const [activeSection, setActiveSection] = useState('home');

  const renderSection = () => {
    switch (activeSection) {
      case 'home':
        return <HomePage />;
      case 'services':
        return <ServicesSection />;
      case 'about':
        return <AboutUsSection />;
      case 'contact':
        return <ContactUsPage />;
      // Add other sections like 'about' and 'contact' as needed
      default:
        return <HomePage />;
    }
  };

  return (
    <div className="App">
      <Navbar onNavClick={setActiveSection} />
      {renderSection()}
    </div>
  );
}

export default App;
