import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <p><strong>TuitionPoint</strong> - Connect, Learn & Excel</p>
    <p>Expert home tutors in Delhi NCR for all classes.</p>
    <p>Support: 9354955543</p>
    <p>© 2024. All rights reserved.</p>
  </footer>
);

export default Footer;
